// State
export const state = () => ({
    currency: [],
    allCurrencies: [],
    exchangeRates: [],
    loading: false,
    clientInfo: {},
    clientWalletInfo: {},
    clientWalletLogs: {},
    referralSettings: [],
    userPermissions: [],
    firstLevelPermission: [],
    currentPermissionCode: 'dashboard',
    locale: 'en-US',
    languages: [],
    menubarTab: [
        {
            icon: 'mdi-fire',
            title: 'dashboard',
            to: '/',
            code: 'dashboard',
        },
        {
            icon: 'mdi-web',
            title: 'frontend',
            code: 'frontend_management',
            children: [
                {
                    title: 'cms',
                    to: '/frontend/cms',
                    code: 'cms',
                },
                {
                    title: 'seo',
                    code: 'seo',
                    to: '/frontend/seo',
                    disabled: true,
                },
            ],
        },
        {
            icon: 'mdi-gamepad-variant',
            title: 'providerManagement',
            code: 'game_provider',
            children: [
                {
                    code: 'game_providers',
                    title: 'providers',
                    to: '/provider/providers',
                },
                {
                    code: 'games',
                    title: 'games',
                    to: '/provider/games',
                },
            ],
        },
        {
            icon: 'mdi-tooltip-image',
            title: 'marketingTool',
            code: 'marketing_tool',
            children: [
                {
                    title: 'advertisementSource',
                    code: 'advertisement_source',
                    to: '/marketing-tools/banners',
                },
                {
                    title: 'marketingPerformance',
                    code: 'marketing_performance',
                    to: '/marketing-tools/marketing-performance',
                },
                {
                    title: 'clickRecord',
                    code: 'click_record',
                    to: '/marketing-tools/click-record',
                },
                {
                    title: 'viewRecord',
                    code: 'view_record',
                    to: '/marketing-tools/view-record',
                },
            ],
        },
        {
            icon: 'mdi-finance',
            title: 'finance',
            parent: 'finance',
            code: 'finance',
            children: [
                {
                    title: 'deposit',
                    child: 'deposit',
                    code: 'deposit',
                    to: '/finance/deposits',
                },
                {
                    title: 'withdrawal',
                    child: 'withdrawal',
                    code: 'withdrawal',
                    to: '/finance/withdrawals',
                },
                {
                    icon: 'mdi-credit-card-multiple',
                    title: 'manualAdj',
                    code: 'manual_adjustment',
                    to: '/finance/manual-adjustment',
                },
                // {
                // 	icon: 'mdi-credit-card-multiple',
                // 	title: 'currencyManagement',
                // 	code: 'currency_management',
                // 	to: '/finance/currencies',
                // 	wl_type: 2,
                // },
                {
                    title: 'exchangeRate',
                    to: '/finance/exchange-rate',
                    code: 'exchange_rate',
                    icon: 'mdi-credit-card-multiple',
                },
                {
                    title: 'bankManagement',
                    code: 'bank_management',
                    child: 'bankManagement',
                    to: '/finance/bank-management',
                },
                {
                    title: 'cryptocurrency',
                    code: 'crypto_currency',
                    child: 'cryptocurrency',
                    to: '/finance/cryptocurrency',
                },
                // {
                // 	title: '3rdPartyPaymentGateway',
                // 	child: '3rdPartyPaymentGateway',
                // 	code: 'third_party_payment_gateway',
                // 	to: '/finance/third-party-payment',
                // },
            ],
        },
        {
            icon: 'mdi-account-multiple',
            title: 'playerManagement',
            code: 'player_management',
            children: [
                {
                    title: 'players',
                    code: 'player_list',
                    to: '/players/players',
                },
                {
                    title: 'onlinePlayers',
                    code: 'online_players',
                    to: '/players/online-players',
                },
                {
                    title: 'missingPlayers',
                    code: 'missing_players',
                    to: '/players/missing-players',
                },
                {
                    title: 'message',
                    code: 'player_message',
                    to: '/players/messages',
                },
            ],
        },
        {
            icon: 'mdi-swap-horizontal',
            title: 'bet',
            code: 'bet',
            children: [
                {
                    title: 'playerBets',
                    code: 'players_bet',
                    to: '/bet/player-bet',
                },
                {
                    title: 'betHistory',
                    code: 'bet_history',
                    to: '/bet/bet-history',
                },
            ],
        },
        {
            icon: 'mdi-account',
            title: 'subOperator',
            code: 'operator',
            display: false,
            children: [
                {
                    title: 'operatorList',
                    code: 'operator_list',
                    to: '/operator/operator-list',
                    disabled: false,
                },
                {
                    title: 'OperatorReport',
                    code: 'operator_report',
                    to: '/operator/operator-report',
                    disabled: false,
                },
                {
                    title: 'GGRReport',
                    code: 'ggr_report',
                    to: '/operator/ggr-report',
                    disabled: false,
                },
                // ? keep it for phase 2
                {
                    icon: 'mdi-poll',
                    title: 'quota',
                    code: 'operator_quota',
                    to: '/operator/quota',
                    disabled: false,
                },
            ],
        },
        {
            icon: 'mdi-account-group-outline',
            title: 'agent',
            code: 'agent',
            to: '/agents',
            children: [
                {
                    title: 'agents',
                    code: 'agent_list',
                    to: '/agents/agents',
                },
                {
                    title: 'settlement',
                    code: 'agent_settlement',
                    to: '/agents/agent-settlement',
                },
            ],
        },
        {
            icon: 'mdi-professional-hexagon',
            title: 'playerSReward',
            code: 'players_reward',
            children: [
                {
                    title: 'promotion',
                    code: 'promotion',
                    to: '/player-reward/promotion',
                },
                {
                    title: 'promotionCode',
                    code: 'promo_code',
                    to: '/player-reward/promotion-code',
                },
                {
                    title: 'rolling',
                    code: 'rolling',
                    to: '/player-reward/rolling-settings',
                },
                {
                    title: 'inviteAndEarn',
                    code: 'player_reward_invite_and_earn',
                    to: '/player-reward/invite-and-earn',
                },
                {
                    title: 'claim',
                    code: 'player_reward_claims',
                    to: '/player-reward/claim',
                },
                {
                    title: 'report',
                    code: 'player_reward_report',
                    to: '/player-reward/report',
                },
                {
                    title: 'rewardResetThreshold',
                    code: 'player_reward_reset_threshold',
                    to: '/player-reward/reward-reset-threshold',
                },
            ],
        },
        {
            icon: 'mdi-poll',
            title: 'report',
            code: 'report',
            children: [
                {
                    title: 'allSummary',
                    code: 'all_summary',
                    to: '/reports/report',
                },
                {
                    title: 'depositSummary',
                    code: 'deposit_summary',
                    to: '/reports/deposit',
                },
                {
                    title: 'withdrawalSummary',
                    code: 'withdrawal_summary',
                    to: '/reports/withdrawal',
                },
                {
                    title: 'companyReport',
                    code: 'company_report',
                    to: '/reports/company-report',
                    disabled: false,
                },
            ],
        },
        {
            icon: 'mdi-account-group',
            title: 'users',
            code: 'admin_management',
            children: [
                {
                    title: 'systemUsers',
                    code: 'admin_list',
                    to: '/users/users',
                },
                {
                    title: 'accessControl',
                    code: 'admin_role',
                    to: '/users/roles',
                },
                {
                    title: 'userActivity',
                    code: 'admin_activity',
                    to: '/users/user-activity',
                },
            ],
        },
        {
            icon: 'mdi-face-agent',
            title: '108Support',
            code: '108_support',
            to: '/108-support',
            children: [
                {
                    title: 'billing',
                    code: '108_billing',
                    to: '/108-support/billing',
                    disabled: true,
                    dev: true,
                },
            ],
        },
        {
            icon: 'mdi-cog',
            title: 'setting',
            code: 'website_setting',
            children: [
                {
                    title: 'operationSetting',
                    to: '/setting/operation-setting',
                    code: 'operation_setting',
                },
                {
                    title: 'turnoverSetting',
                    code: 'turnover_setting',
                    to: '/setting/turnover-setting',
                },
                {
                    title: 'websiteMaintenance',
                    code: 'website_maintenance',
                    to: '/setting/website-maintenance',
                },
                {
                    title: 'suspendRegistration',
                    to: '/setting/suspend-registration',
                    code: 'suspend_registration',
                },
                {
                    title: 'adminRiskControl',
                    code: 'admin_risk_control',
                    to: '/setting/admin-risk-control',
                },
                {
                    title: 'reCaptchaSetting',
                    code: 'recaptcha_setting',
                    to: '/setting/recaptcha-setting',
                },
                // {
                // 	title: 'depositFlowList',
                // 	to: '/setting/deposit-flow-list',
                // 	code: "deposit_flow_setting",
                // 	wl_type: 2,
                // },
                {
                    code: 'language_setting',
                    title: 'languageSetting',
                    to: '/setting/languages',
                },
                {
                    code: 'language_strings',
                    title: 'language_strings',
                    to: '/setting/language-strings',
                },
                {
                    title: 'promotionClaimSetting',
                    to: '/setting/promotion-claim-setting',
                    code: 'promotion_claim_setting',
                },
                // {
                // 	title: 'playerRegistration',
                // 	to: '/setting/player-registration',
                // 	code: "player_registration_setting",
                // 	wl_type: 2,
                // },
                {
                    title: 'operatorCustomDomain',
                    to: '/setting/operator-custom-domain',
                    code: 'operator_domain',
                },
                {
                    title: 'ipWhitelist',
                    to: '/setting/ip-whitelist',
                    code: 'ip_whitelist_setting',
                },
            ],
        },
    ],
    menubar: [], // this is the menubar after doing permissions check
    // globalSelectedCurrency: 'THB', // this currency is for the currency v-select in Navbar, this currency is for query only, it's being used in axios.js
    currencyLoading: false,
    newWithdrawalRequest: false,
    customBreadcrumbsItems: [],
    temporaryMenubarId: null,
    previousPlayerRoute: null,
    displayRechargeModal: false,

    // cancel token
    settingCancelToken: {},
})

// Actions
export const actions = {
    // ! Currency
    // Get all
    async currency({ state, commit }, params) {
        if (typeof params === 'object') {
            if (!params?.is_active && params.is_active !== false) {
                params.is_active = true
            }
        } else if (!params) {
            params = { is_active: true }
        }
        try {
            commit('setCurrencyLoading', true)
            await this.$axios.get('/currency', { params }).then((response) => {
                // Set default currency in case currency cookie is not set
                if (!this.$cookie.get('currency')) {
                    if (response.data.length > 0) {
                        // const defaultCurrency = state.globalSelectedCurrency
                        // const checkIfTheDefaultCurrencyExist = response.data.find((el) => el.code === defaultCurrency)
                        // if (checkIfTheDefaultCurrencyExist) {
                        // 	this.$cookie.set('currency', checkIfTheDefaultCurrencyExist.code)
                        // 	this.$cookie.set('currency_type', checkIfTheDefaultCurrencyExist.type)
                        // } else {
                        // 	this.$cookie.set('currency', response.data[0].code)
                        // 	this.$cookie.set('currency_type', response.data[0].type)
                        // }
                    }
                }
                commit('setCurrency', response.data)
                commit('setCurrencyLoading', false)
            })
        } catch (error) {
            commit('setCurrencyLoading', false)
            return false
        }
    },
    // used for upload socket value
    setClientWalletInfo({ commit }, data) {
        commit('setClientWalletInfo', data)
    },
    // Referral System
    async referralSettings({ commit }, params) {
        commit('setLoading', true)
        await this.$axios
            .get('/settings', { params })
            .then((response) => {
                commit('referralSettings', response.data)
                commit('setLoading', false)
            })
            .catch(() => false)
    },
    async updateReferralSettings({ commit, dispatch }, form) {
        commit('setLoading', true)
        const id = form.short_code
        delete form.short_code

        const response = await this.$axios
            .put(`/settings/${id}`, form)
            .then((response) => {
                dispatch('referralSettings')
                commit('setLoading', false)
                return true
            })
            .catch(() => false)
        return response
    },
    async userPermissions({ commit }, params) {
        commit('setLoading', true)
        if (process.client) {
            const permissionTime = sessionStorage.getItem('permission-time')

            // This logic is to block requests
            if (permissionTime) {
                const lastTimePermission = permissionTime
                const gapTimeForBlockRequest = 4000 // 4 seconds
                const nowTime = new Date().getTime()
                if (nowTime - lastTimePermission <= gapTimeForBlockRequest)
                    return
            }
            sessionStorage.setItem('permission-time', new Date().getTime())
        }

        const response = await this.$axios
            .get('/users/permissions', { params })
            .then((response) => {
                // DOING: map for first level permissions
                const p = response.data.map((item) => {
                    const re = item.permissions.filter((i) => i.can_view)
                    return { ...item, display: re.length > 0 }
                })
                commit('firstLevelPermission', p)
                // DOING: this for loop for sub tab permissions
                const newArr = []
                for (const item of response.data) {
                    for (const i of item.permissions) {
                        newArr.push({ ...i })
                    }
                }
                commit('setUserPermissions', newArr)
                commit('setLoading', false)
                // DOING: don't have any permissions
                const canViewAtLeastOne = p.find((el) => el.display === true)
                if (!canViewAtLeastOne) this.$router.push('/no-permissions')
                return response.data
            })
            .catch(() => false)
        return response
    },
    locale({ commit }, lang) {
        commit('locale', lang)
    },
    permissionCode({ commit }, code) {
        commit('setPermissionCode', code)
    },
    displayQuotaWarning({ commit }, currency) {
        try {
            this.$axios
                .get(`/statistics/dashboard/client-quota/${currency}`)
                .then((response) => {
                    const remainingQuota = parseFloat(
                        response?.data?.remaining_quota
                    )
                    const totalQuota = parseFloat(response?.data?.total_quota)

                    const limitedPercentOfTotal = 0.3 * totalQuota // 0.3 is 30%
                    const result = remainingQuota <= limitedPercentOfTotal
                    commit('setDisplayRechargeModal', result)
                })
                .catch(() => false)
        } catch (error) {}
    },
    getItemsWithDisplayStatus({ state, dispatch }) {
        const userType = this.$cookie.get('user')?.user_type
        if (userType === 1) return state.menubarTab // If the user is super admin, no need to check any permissions

        const userPermissions = state.userPermissions
        const firstLevelPermission = state.firstLevelPermission
        const itemsData = state.menubarTab
        const items = itemsData.map((item) => {
            // DOING: first tab permission
            if (item.code) {
                // start checking permissions, then stop here
                const getPermissionsData = firstLevelPermission.find(
                    (permission) => permission.group_code === item.code
                )
                if (getPermissionsData) {
                    const { display: canView } = getPermissionsData
                    return { ...item, display: canView }
                }
                return { ...item, display: false }
            }
            let hasAtLeaseOneDisplay = false // This variable is used for checking if the item's children also doesn't any permission to access
            // DOING: check sub tab permissions
            if (item.children) {
                // start checking permissions by submenu, then stop here
                item.children.map((submenuChild, submenuIndex) => {
                    if (submenuChild.code) {
                        const getPermissionsData = userPermissions.find(
                            (permission) =>
                                permission.code === submenuChild.code
                        )
                        if (getPermissionsData) {
                            const { can_view: canView } = getPermissionsData

                            if (canView) {
                                hasAtLeaseOneDisplay = true
                            }

                            item.children[submenuIndex] = {
                                ...submenuChild,
                                display: canView,
                            }
                            return submenuChild
                        }
                    }
                    item.children[submenuIndex] = {
                        ...submenuChild,
                        display: false,
                    }
                    return submenuChild
                })
            }
            item.display = hasAtLeaseOneDisplay

            return item
        })
        return items
    },
    async addCurrenciesToSubmenu({ state, dispatch }) {
        const bankManagementGroup = {
            icon: 'mdi-finance',
            title: 'bankManagement',
            children: [
                {
                    title: 'bankAccounts',
                    code: 'bank_management',
                    to: '/bank-accounts',
                },
                {
                    title: 'selfManagedPaymentGateway',
                    to: '/self-managed-payment-gateway',
                },
                {
                    title: 'log',
                    to: '/log',
                    disabled: true,
                    dev: true,
                },
            ],
        }
        const cryptocurrencyChildren = {
            icon: 'mdi-finance',
            title: 'cryptocurrency',
            children: [
                {
                    title: 'walletList',
                    to: '/wallet-list',
                },
                {
                    title: 'log',
                    to: '/log',
                },
            ],
        }
        const currency = state.currency
        // All the currencies in array
        const currenciesCode = currency.map((item) => {
            return { title: item.code, to: `/${item.code}` }
        })
        currenciesCode.unshift({ title: 'all', to: '/ALL' })
        let items = await dispatch('getItemsWithDisplayStatus')

        // This logic will be better after I finished all my tasks
        items = items.map((item) => {
            if (item.title === 'finance') {
                if (item.children) {
                    const neededIndexToAdd = item.children.findIndex(
                        (el) => el.title === 'bankManagement'
                    )
                    item.children[neededIndexToAdd].children =
                        bankManagementGroup.children
                }
            }
            return item
        })
        items = items.map((item) => {
            if (item.title === 'finance') {
                if (item.children) {
                    const neededIndexToAdd = item.children.findIndex(
                        (el) => el.title === 'cryptocurrency'
                    )
                    item.children[neededIndexToAdd].children =
                        cryptocurrencyChildren.children
                }
            }
            return item
        })
        // items = items.map((item) => {
        // 	if (item.title === 'finance') {
        // 		if (item.children) {
        // 			const neededIndexToAdd = item.children.findIndex(
        // 				(el) => el.title === '3rdPartyPaymentGateway'
        // 			)
        // 			item.children[neededIndexToAdd].children =
        // 				thirdPartyChildren.children
        // 		}
        // 	}
        // 	return item
        // })
        return items
    },
    async redirectToHasPermissionRoute({ commit, dispatch, rootState }, login) {
        // login is for checking if dispatch from Login.vue page
        const userType = this.$cookie.get('user')?.user_type
        if (rootState?.filterCurrency?.length === 0) {
            await dispatch('master/baseCurrency', {}, { root: true })
        }
        if (userType !== 1) {
            const permission = await dispatch('userPermissions')
            if (permission === false) {
                dispatch('auth/logout', {}, { root: true })
            }
        }
        const items = await dispatch('addCurrenciesToSubmenu')
        if (userType === 1) {
            commit('menubar', [...items])
            return items
        }

        // checking the user Permissions for dashboard page
        let redirectionPage = null
        const findDashboard = items.find((item) => item.code === 'dashboard')

        // loop until we find one existing page
        let itemLength = items.length
        while (redirectionPage === null && itemLength !== 0) {
            itemLength--
            if (findDashboard) {
                if (findDashboard.display === true) {
                    redirectionPage = '/'
                } else {
                    const findRouteWhichUserHasPermission = items.find(
                        (item) => item.display === true
                    )

                    if (findRouteWhichUserHasPermission) {
                        if (findRouteWhichUserHasPermission.to) {
                            // Check first, if the tab doesn't includes children tab, use the tab to be main route
                            // Otherwise if the first tab in children tab
                            if (
                                !findRouteWhichUserHasPermission?.children
                                    ?.length
                            ) {
                                redirectionPage =
                                    findRouteWhichUserHasPermission.to
                            } else {
                                redirectionPage =
                                    findRouteWhichUserHasPermission.children[0]
                                        .to
                            }
                        } else if (
                            findRouteWhichUserHasPermission.children &&
                            findRouteWhichUserHasPermission.children.length > 0
                        ) {
                            const subChildrenItems =
                                findRouteWhichUserHasPermission.children.find(
                                    (el) => el.display !== false
                                )
                            redirectionPage = subChildrenItems.to
                        }
                    }
                }
            }
        }

        // If the user doesn't have any page permissions
        if (itemLength === 0 && !redirectionPage && this.$cookie.get('token')) {
            this.$router.push('/no-permissions')
            return items
        }

        // If the user is new for login, means there is not last login routes
        // then redirect to the first page which the user has permission to access
        if (!this.$cookie.get('routePath') && login) {
            this.$router.push(redirectionPage || '/')
            commit('menubar', [...items])
            return items
        } else {
            const routePath = this.$cookie.get('routePath')
            let hasPermissionForLastLoginPath = false

            // for token expired, this case; redirect to the last login route
            // this is checking if the last login route is accessible
            if (routePath && login) {
                // getting the last login main route
                const splitRoutePath = routePath.split('/')

                if (splitRoutePath.length !== 0) {
                    const lastLoginPath = splitRoutePath[1]
                    for (let i = 0; i < items.length; i++) {
                        const item = items[i]
                        // if the item has subitems, check subitems first code
                        // in case the user has permission for only some pages in submenus
                        if (item.children && item.children.length > 0) {
                            item.children.map((x) => {
                                if (
                                    x.to.includes(lastLoginPath) &&
                                    x.display !== false
                                ) {
                                    if (
                                        x.display === undefined &&
                                        item.display !== false
                                    ) {
                                        hasPermissionForLastLoginPath = true
                                    } else {
                                        hasPermissionForLastLoginPath = false
                                    }
                                }
                                return x
                            })
                            if (hasPermissionForLastLoginPath) break
                        } else if (
                            item.to.includes(lastLoginPath) &&
                            item.display !== false
                        ) {
                            hasPermissionForLastLoginPath = true
                            break
                        }
                    }
                }
            }

            if (hasPermissionForLastLoginPath) {
                if (this.$cookie.get('routePath') === '/login')
                    this.$router.push('/')
                else this.$router.push(this.$cookie.get('routePath') || '/')
            } else if (!login) {
                // In case refresh page
                // Check if the current page is still accessible
                const permissionCode = this.$cookie.get('permissionCode')
                const filters = (el) => {
                    if (el.children && el.children.length > 0) {
                        el.children.map((x) => {
                            if (
                                x.code === permissionCode &&
                                x.display !== false
                            ) {
                                if (
                                    x.display === undefined &&
                                    el.display !== false
                                ) {
                                    return true
                                } else {
                                    return false
                                }
                            }
                            return false
                        })
                    }
                    return el.code === permissionCode && el.display !== false
                }
                const getOnRefreshPageItem = items.find(filters)
                if (!getOnRefreshPageItem) {
                    this.$router.push(
                        this.$router.history.current.fullPath || '/'
                    )
                } else {
                   this.$router.push(getOnRefreshPageItem.to || '/')
                }
            }
            commit('menubar', [...items])
            return items
        }
    },
}

// Mutations
export const mutations = {
    setCurrency: (state, v) => {
        state.currency = v
    },
    setAllCurrencies: (state, v) => {
        state.allCurrencies = v
    },
    setLoading: (state, type) => {
        state.loading = type
    },
    setClientInfo: (state, v) => {
        if (v.client_code) {
            v.client_code = v.client_code + '00000'
        }
        state.clientInfo = v
    },
    referralSettings: (state, v) => {
        state.referralSettings = v
    },
    setUserPermissions: (state, v) => {
        state.userPermissions = v
    },
    firstLevelPermission: (state, v) => {
        state.firstLevelPermission = v
    },
    setPermissionCode: (state, v) => {
        state.currentPermissionCode = v
    },
    locale: (state, v) => {
        state.locale = v
    },
    menubar: (state, v) => {
        state.menubar = v
    },
    setLanguages: (state, v) => {
        state.languages = v
    },
    // setGlobalSelectedCurrency: (state, v) => {
    // 	state.globalSelectedCurrency = v || 'THB'
    // },
    setCurrencyLoading: (state, v) => {
        state.currencyLoading = v
    },
    setNewWithdrawalRequest: (state, v) => {
        state.newWithdrawalRequest = v
    },
    setSettingCancelToken: (state, data) => {
        const oldData = { ...state.settingCancelToken }
        state.settingCancelToken = {
            ...oldData,
            ...data,
        }
    },
    setCustomBreadcrumbsItems: (state, data) => {
        state.customBreadcrumbsItems = data
    },
    setTemporaryMenubarId: (state, data) => {
        state.temporaryMenubarId = data
    },
    setPreviousPlayerRoute: (state, data) => {
        state.previousPlayerRoute = data
    },
    setDisplayRechargeModal: (state, data) => {
        state.displayRechargeModal = data
    },
}

// Getters
export const getters = {
    findCurrencyById: (state) => (id) => {
        return state.currency.length > 0
            ? state.currency.find((object) => object.id === id).code
            : ''
    },
    sideNavCurrency: (state) => (currency) => {
        return state.currency.length > 0
            ? state.currency.find((object) => object.code === currency)?.code
            : null
    },
    searchLanguages: (state) => (currency, search) => {
        if (!currency && !search) return state.languages

        let languages = state.languages
        if (search) {
            const filterCondition = (el) => {
                if (el.name.toLowerCase().includes(search.toLowerCase())) {
                    return true
                }
                return false
            }
            languages = languages.filter(filterCondition)
        }
        if (currency) {
            const getSelectedCurrency = state.currency.find(
                (el) => el.code === currency
            )

            if (getSelectedCurrency) {
                const getSupportedLanguage =
                    getSelectedCurrency.supported_languages.map((data) => {
                        return {
                            language: data.language,
                            is_active: data.is_active,
                            is_default: data.is_default,
                        }
                    })

                languages = languages.filter((el) => {
                    if (
                        getSupportedLanguage.some(
                            (sEl) => sEl.language === el.code
                        )
                    ) {
                        return true
                    }
                    return false
                })
                languages = languages.map((data) => {
                    const form = { ...data }
                    // check if the language is active or not
                    const getLanguageStatusByCurrency =
                        getSelectedCurrency.supported_languages.find(
                            (el) => el.language === data.code
                        )

                    form.is_active_bo = getLanguageStatusByCurrency.is_active
                    form.is_default = getLanguageStatusByCurrency.is_default
                    return form
                })
            }
        }

        return languages
    },
    getLanguage: (state) => (code) => {
        const language = state.languages.find((el) => el.code === code)
        if (language) {
            return language.name
        }
        return '-'
    },
    currencies: (state) => {
        const currency = [...state.currency]
        const data = currency.sort((a, b) => a.code.localeCompare(b.code))
        return data.sort((a, b) => {
            // Then, sort by is_default only
            if (a.is_default !== b.is_default) {
                return b.is_default - a.is_default
            }
            return 0
        })
    },
    getMenuForBreadcrumbs: (state) => {
        const menus = {}

        if (state.menubar.length === 0) {
            return {}
        }

        state.menubar.forEach((el) => {
            if (el.children && el.children.length > 0) {
                el.children.forEach((childEl) => {
                    if (childEl.children && childEl.children.length > 0) {
                        childEl.children.forEach((grandChild) => {
                            menus[childEl.to + grandChild.to] = {
                                title: grandChild.title,
                                parent: childEl.title,
                                to: childEl.to + grandChild.to,
                                grandParent: el.title,
                                grandTo: el.to,
                            }
                        })
                    } else {
                        menus[childEl.to] = {
                            title: childEl.title,
                            parent: el.title,
                            to: childEl.to,
                        }
                    }
                })
            } else if (el.to) {
                menus[el.to] = {
                    title: el.title,
                    to: el.to,
                }
            }
        })

        return menus
    },
}
